@charset "UTF-8";
.btn {
  position: relative;
  display: inline-block;
  font-weight: 600;
  margin: 10px auto;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  text-decoration: none !important; }
  .btn.filled {
    width: 200px;
    height: 90px;
    line-height: 90px;
    background-color: #1f2c5c;
    color: white;
    margin: 0; }
    .btn.filled:hover {
      opacity: 0.9; }
  .btn.circle {
    width: 230px;
    height: 60px;
    line-height: 60px;
    background-color: #1f2c5c;
    color: #fdf3a0;
    border-radius: 40px;
    outline: none; }
    .btn.circle:hover {
      opacity: 0.9; }

.btn-blue {
  background: #1f2c5c;
  color: #fff !important; }

.hero {
  padding-top: 90px;
  position: relative; }
  .hero__images {
    height: calc(100vh - 90px); }
  .hero__logo {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 900px;
    transform: translate(-50%, 0%);
    z-index: 3000; }
  .hero__title {
    background-image: url(../images/logo__title.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 10%;
    left: 49%;
    transform: translate(-50%, -98%);
    width: 250px;
    height: 260px;
    display: none; }
    .hero__title-logo {
      background-image: url(../images/hero-img01.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
      height: 260px;
      display: none; }
    .hero__title-logo2 {
      background-image: url(../images/hero-marumasa.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, 300%);
      width: 200px;
      height: 50px;
      display: none; }
    .hero__title-logo3 {
      background-image: url(../images/hero-scroll.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 300%;
      left: 50%;
      transform: translate(-50%, 270%);
      width: 50px;
      height: 100px;
      display: none; }
  .hero__movie {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 130%;
    min-height: 130%; }
    .hero__movie-wrapper {
      position: relative;
      width: 100vw;
      height: calc(100vh - 90px);
      overflow: hidden; }
    .hero__movie-dotto {
      background: url(../images/ovlay.png);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }

.mobile-menu {
  position: fixed;
  right: -60px;
  top: 60px;
  width: 300px; }
  .mobile-menu__nav {
    padding-top: 120px; }
  .mobile-menu__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .mobile-menu__li {
    list-style: none;
    width: 100%;
    text-align: center;
    position: relative; }
    .mobile-menu__li > a {
      text-decoration: none;
      color: #1f2c5c;
      width: 100%; }
    .mobile-menu__li-facebook {
      list-style: none; }
    .mobile-menu__li:after {
      content: '';
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      display: inline-block;
      width: 90%;
      height: 2px;
      vertical-align: middle; }
  .mobile-menu__link {
    display: block;
    height: 60px;
    line-height: 60px;
    color: #534741;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold; }
    .mobile-menu__link > .fab {
      font-size: 32px; }
  .mobile-menu__h1 > a:hover {
    color: #323232;
    text-decoration: none; }
  .mobile-menu__btn {
    background-color: unset;
    border: none;
    outline: none !important;
    cursor: pointer; }
    .mobile-menu__btn > span {
      background-color: #1f2c5c;
      width: 35px;
      height: 2px;
      display: block;
      margin-bottom: 9px;
      transition: transform .7s; }
      .mobile-menu__btn > span:last-child {
        margin-bottom: 0; }
  .mobile-menu__cover {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 4000;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
    transition: all .8s;
    overflow-y: scroll; }
  .mobile-menu__main {
    padding: 0;
    perspective: 2000px;
    transform-style: preserve-3d; }
  .mobile-menu__item {
    list-style: none;
    display: block;
    transform: translate3d(0, 0, -1000px);
    padding: 0 40px;
    transition: transform 0.3s, opacity 0.2s;
    opacity: 0; }
  .mobile-menu__h1 {
    margin: 30px auto 20px;
    line-height: 50px;
    text-align: center; }
    .mobile-menu__h1 > img {
      width: 100px;
      height: 50px; }
  .mobile-menu__about {
    font-size: 20px;
    font-weight: normal; }
  .mobile-menu__text {
    width: 90%;
    margin: 0 auto; }

.menu-open .mobile-menu__cover {
  height: 100%;
  transition: all 0.8s;
  overflow-y: scroll; }

.menu-open .mobile-menu__item {
  transform: none;
  opacity: 1; }
  .menu-open .mobile-menu__item:nth-child(1) {
    transition-delay: 0.07s; }
  .menu-open .mobile-menu__item:nth-child(2) {
    transition-delay: 0.14s; }
  .menu-open .mobile-menu__item:nth-child(3) {
    transition-delay: 0.21s; }
  .menu-open .mobile-menu__item:nth-child(4) {
    transition-delay: 0.28s; }
  .menu-open .mobile-menu__item:nth-child(5) {
    transition-delay: 0.35s; }

.menu-open .mobile-menu__btn > span {
  background-color: black; }
  .menu-open .mobile-menu__btn > span:nth-child(1) {
    transition-delay: 70ms;
    transform: translateY(11px) rotate(135deg); }
  .menu-open .mobile-menu__btn > span:nth-child(2) {
    transition-delay: 0s;
    transform: translateX(-18px) scaleX(0); }
  .menu-open .mobile-menu__btn > span:nth-child(3) {
    transition-delay: 140ms;
    transform: translateY(-11px) rotate(-135deg); }

/* loadinging背景画面設定　*/
.loading {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2999;
  background: #1f2c5c;
  text-align: center;
  color: #fff;
  padding-top: 90px; }
  .loading__images {
    width: 100%;
    height: 100%; }
  .loading__cover {
    background-image: url(../images/hero-cover.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loading__logo {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 900px;
    transform: translate(-50%, 0%);
    z-index: 3000; }
  .loading__title {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -51%);
    font-size: 30px;
    font-weight: 700;
    color: white;
    padding-bottom: 300px;
    opacity: 0;
    letter-spacing: -5px; }
    .loading__title span {
      opacity: 0;
      -webkit-transition: 0.6s ease-in-out;
      transition: 0.6s ease-in-out; }
  .loading__title01 {
    background-image: url(../images/hero-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 260px;
    display: none; }
  .loading__title02 {
    background-image: url(../images/hero-marumasa.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 300%);
    width: 200px;
    height: 50px;
    display: none; }

.parallax {
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  text-align: center; }
  .parallax.bg-1 {
    background-image: url(../images/palalax.png); }

.is-ios .parallax {
  background-attachment: scroll; }

.bv-video-wrap {
  height: 600px !important;
  z-index: -1 !important; }

.bv-video-wrap::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(#111 30%, transparent 31%), radial-gradient(#111 30%, transparent 31%);
  background-size: 4px 4px;
  background-position: 0 0, 2px 2px;
  z-index: 1; }

.bv-video-dotto {
  background: url(../images/ovlay.png);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

body {
  font-family: YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Sogoe UI", sans-serif; }

img {
  max-width: 100%;
  vertical-align: bottom; }

.font-mp, .sub-title {
  font-family: "M PLUS Rounded 1c", sans-serif; }

.font-sm {
  font-size: 14px; }

.font-md, .header__title-syokunin, .header__li > a {
  font-size: 18px; }

.font-lr {
  font-size: 25px; }

.font-lg, .header .logo {
  font-size: 36px; }

.mb-sm {
  margin-bottom: 16px !important; }

.mb-lg {
  margin-bottom: -55px !important; }

.pt-lg {
  padding-top: 170px; }

.pb-sm {
  padding-bottom: 16px; }

.pb-lg {
  padding-bottom: 80px !important; }

a {
  text-decoration: none !important; }

.content-width {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px; }

.sub-title {
  font-size: 34px;
  color: black;
  font-weight: 700;
  margin-bottom: 20px; }

#global-container {
  overflow: hidden; }

.mobile-menu__btn {
  display: none; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #1f2c5c;
  font-weight: bold;
  z-index: 2500; }
  .header.triggered {
    background-color: rgba(255, 255, 255, 0.9); }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 50px; }
  .header__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none; }
  .header__li {
    margin-left: 50px; }
    .header__li > a {
      text-decoration: none !important;
      text-transform: uppercase;
      color: #1f2c5c;
      font-weight: bold; }
  .header .logo {
    justify-content: center; }
    .header .logo > a {
      color: #1f2c5c; }

.main {
  background-image: url(../images/main-back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative; }
  .main__title-wrapper {
    text-align: center;
    padding-top: 100px; }
  .main__subtitle {
    margin-top: 30px; }
  .main__images {
    background-image: url(../images/main-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 900px;
    height: 450px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: -7%;
    left: 50%;
    transform: translate(-50%, -7%);
    z-index: 1; }
    .main__images::after {
      content: "";
      display: block;
      padding-top: 40%; }

.work {
  background-image: url(../images/cover01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fdf3a0;
  padding: 100px 0; }
  .work__inner {
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0;
    z-index: 1;
    position: relative; }
  .work__title {
    text-align: center;
    margin-top: 40px; }
  .work__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative; }
    .work__h1::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 200px;
      height: 10px;
      background-color: #fdf3a0; }
  .work__des {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
  .work__txt-wrapper {
    width: 50%;
    margin-top: 50px;
    position: relative;
    left: 70px; }
  .work__images {
    width: 80%;
    position: relative;
    right: -13%; }
    .work__images > img {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
  .work__person01 {
    position: absolute;
    top: 500px;
    right: -100px; }
  .work__person02 {
    position: absolute;
    bottom: -160px;
    left: -80px; }
  .work__info {
    text-align: center;
    margin-top: 80px; }
  .work__h2 {
    font-size: 34px;
    color: #1f2c5c;
    font-weight: 700; }
  .work__panel {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .work__panel-images {
      width: 21%;
      height: 21%;
      margin-left: 30px;
      margin-bottom: 40px; }
      .work__panel-images:nth-child(1), .work__panel-images:nth-child(5) {
        margin-left: 0; }
  .work__btn {
    display: block;
    text-align: center; }

.about {
  background-image: url(../images/cover03.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fdaca0;
  padding: 100px 0;
  z-index: 3; }
  .about__inner {
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0;
    z-index: 1; }
  .about__title {
    text-align: center;
    position: relative;
    z-index: 2; }
  .about__person03 {
    position: absolute;
    z-index: 1;
    right: 230px;
    top: -10px; }
  .about__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative; }
    .about__h1::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 450px;
      height: 10px;
      background-color: #fdaca0; }
  .about__des {
    margin-top: 80px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start; }
    .about__des-title {
      width: 400px;
      padding-right: 30px; }
  .about__txt-wrapper {
    margin-top: 70px;
    width: 50%;
    position: relative;
    right: 70px; }
  .about__images {
    width: 80%;
    position: relative;
    left: -15%; }
    .about__images > img {
      object-fit: cover;
      width: 100%;
      border-radius: 20px; }
  .about__panel {
    width: 29%;
    margin-left: 30px; }
    .about__panel:first-child {
      margin-left: 0; }
    .about__panel-title {
      font-size: 20px;
      font-weight: 700;
      color: #1f2c5c;
      text-align: center;
      margin-bottom: 10px;
      height: 50px; }
    .about__panel-txt {
      color: #1f2c5c;
      margin-top: 20px; }
    .about__panel-wrapper {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center; }
    .about__panel-images {
      text-align: center; }
      .about__panel-images > img {
        width: 100%;
        border-radius: 20px; }

.staff {
  background-image: url(../images/cover02.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #6fabe2;
  padding: 100px 0;
  z-index: 100; }
  .staff__inner {
    width: 77%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0; }
  .staff__title {
    text-align: center; }
  .staff__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative; }
    .staff__h1::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 250px;
      height: 10px;
      background-color: #6fabe2; }
  .staff__mainimages {
    background-image: url(../images/main-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1; }
    .staff__mainimages::after {
      content: "";
      display: block;
      padding-top: 40%; }
  .staff__des {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; }
    .staff__des:nth-child(2n) {
      flex-direction: row-reverse; }
    .staff__des p,
    .staff__des div {
      margin-bottom: 0; }
    .staff__des-titlewrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
  .staff__txt {
    margin-top: 30px; }
    .staff__txt-wrapper {
      width: 70%;
      margin-top: 50px;
      position: relative;
      left: 90px; }
      .staff .staff__des:nth-child(2n) .staff__txt-wrapper {
        right: 70px;
        left: auto; }
  .staff__images {
    width: 100%;
    position: relative;
    left: 195px; }
    .staff .staff__des:nth-child(2n) .staff__images {
      left: auto;
      right: 215px; }
    .staff__images > img {
      object-fit: cover;
      width: 100%;
      border-radius: 20px; }
  .staff__person04 {
    position: absolute;
    top: -300px;
    right: 162px;
    z-index: 1; }
  .staff__info {
    text-align: center;
    margin-top: 80px; }
  .staff__h2 {
    font-size: 24px;
    color: #1f2c5c;
    font-weight: 700; }
  .staff__panel {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .staff__panel-images {
      width: 21%;
      height: 21%;
      margin-left: 30px;
      margin-bottom: 40px; }
      .staff__panel-images:nth-child(1), .staff__panel-images:nth-child(5) {
        margin-left: 0; }
  .staff__btn {
    display: block;
    text-align: center; }
  .staff__info-wrap {
    display: none; }

.contact {
  width: 100%;
  height: 160px;
  background-color: #d26889;
  text-align: center;
  padding: 20px 0;
  position: relative; }
  .contact > a {
    color: #fdf3a0;
    display: inline-block; }
  .contact > h1 {
    font-size: 43px;
    font-weight: 700px; }
  .contact p {
    margin: 0; }
  .contact img {
    margin-left: 600px;
    position: relative;
    bottom: 70px; }
  .contact-br {
    display: none; }

.footer {
  margin-top: 50px; }
  .footer__inner {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .footer__adress {
    display: block;
    margin-top: 20px; }
    .footer__adress p {
      margin: 0; }
  .footer__bottom {
    text-align: center;
    margin: 50px 0; }
  .footer__scroll {
    text-align: center;
    position: relative; }
    .footer__scroll > a {
      position: fixed;
      bottom: 0;
      right: 0;
      display: block;
      width: 70px;
      height: 70px;
      background-color: #1f2c5c;
      z-index: 300; }
      .footer__scroll > a > img {
        margin-top: 30px; }

@media screen and (min-width: 1900px) {
  .staff__images {
    left: 220px; } }

@media screen and (max-width: 1280px) {
  .header__li {
    margin-left: 40px; }
  .staff__images {
    left: 150px; }
    .staff .staff__des:nth-child(2n) .staff__images {
      right: 130px; } }

@media screen and (max-width: 1120px) {
  .sub-title {
    font-size: 30px; }
  .header__li {
    margin-left: 20px; }
    .header__li > a {
      font-size: 15px; } }

@media screen and (max-width: 960px) {
  .mobile-menu__btn {
    display: block;
    margin-right: 30px; }
  .header {
    z-index: 4500; }
    .header__nav {
      display: none; }
    .header__li {
      margin-left: 30px; }
  .main {
    height: 600px; }
    .main__images {
      width: 650px;
      bottom: -15%; }
  .work__des {
    flex-direction: column; }
  .work__txt-wrapper {
    width: 90%;
    margin: 0 auto;
    left: auto; }
  .work__images {
    right: -33%; }
  .work__person01 {
    width: 160px;
    top: 750px; }
  .work__person02 {
    width: 110px;
    bottom: -120px;
    left: -40px; }
  .work__info {
    margin-top: 100px; }
  .work__panel > img {
    width: 40%; }
    .work__panel > img:nth-child(3), .work__panel > img:nth-child(7) {
      margin-left: 0; }
  .about__des {
    flex-direction: column; }
    .about__des-title {
      width: auto; }
  .about__txt-wrapper {
    width: 80%;
    right: 0;
    margin: 0 auto; }
  .about__images {
    left: -14%; }
  .about__h1 {
    font-size: 34px; }
    .about__h1::after {
      width: 360px; }
  .about__panel {
    margin-top: 40px;
    margin-left: 0;
    width: 70%; }
    .about__panel-wrapper {
      flex-direction: column;
      align-items: center; }
    .about__panel-txt {
      margin-top: 20px; }
  .about__person03 {
    width: 80px;
    right: 100px; }
  .staff__backcolor {
    height: 5000px !important; }
  .staff__des {
    flex-direction: column;
    margin-top: 100px; }
    .staff__des:nth-child(2n) {
      flex-direction: column; }
  .staff__txt-wrapper {
    width: 90%;
    margin: 0 auto;
    position: relative;
    left: 0; }
    .staff .staff__des:nth-child(2n) .staff__txt-wrapper {
      width: 90%;
      right: 0;
      left: 0; }
  .staff__images {
    width: 90%;
    position: relative;
    left: 170px;
    margin-top: 50px; }
    .staff .staff__des:nth-child(2n) .staff__images {
      right: 105px; }
  .staff__person04 {
    width: 160px;
    top: -180px;
    right: 102px; }
  .staff__mainimages {
    width: 95%; }
  .staff__txt {
    display: none; }
  .staff__images {
    display: none; }
  .staff__info-wrap {
    display: flex;
    flex-direction: column-reverse; }
  .staff__info-txt {
    width: 90%;
    margin: 0 auto; }
  .staff__info-images {
    object-fit: cover; }
    .staff__info-images > img {
      margin-left: 110px;
      margin-top: 50px;
      margin-bottom: 20px;
      border-radius: 20px; }
      .staff .staff__des:nth-child(2n) .staff__info-images > img {
        margin-left: -110px; }
  .footer__inner {
    flex-direction: column; }
  .footer__right {
    margin-top: 30px; } }

@media screen and (max-width: 780px) {
  .work__person01 {
    top: 550px;
    right: -50px; } }

@media screen and (max-width: 600px) {
  .main__title-wrapper {
    margin: 0 auto;
    width: 90%; }
  .work__person01 {
    top: 650px; }
  .parallax {
    height: 450px; }
    .parallax-movie {
      height: 450px; }
  .about__panel-title {
    font-size: 16px; }
  .about__panel-txt {
    font-size: 14px; }
  .staff__des-titlewrap {
    flex-direction: column;
    align-items: center; }
  .staff__des-titleimages {
    margin-top: 20px; }
  .staff__images {
    left: 110px; }
    .staff .staff__des:nth-child(2n) .staff__images {
      right: 70px; }
  .staff__person04 {
    right: 50px; }
  .contact p {
    font-size: 14px; } }

@media screen and (max-width: 700px) {
  .main {
    height: 500px; }
    .main__images {
      width: 500px;
      bottom: -27%; }
  .contact > h1 {
    font-size: 36px; }
  .contact img {
    margin-left: 85%; } }

@media screen and (max-width: 480px) {
  .sub-title {
    font-size: 20px;
    text-align: center; }
  .bv-video-wrap {
    height: 480px !important; }
  .header {
    height: 70px; }
    .header__title-company {
      font-size: 26px; }
  .loading__title {
    letter-spacing: -3px;
    font-size: 22px;
    padding-bottom: 290px; }
  .loading__logo {
    top: 58%; }
  .loading__title01 {
    width: 180px;
    height: 180px;
    top: 67%;
    transform: translate(-50%, -73%); }
  .loading__title02 {
    transform: translate(-50%, 120%);
    width: 150px;
    height: 50px; }
  .hero {
    padding-top: 70px; }
    .hero__logo {
      top: 50%; }
    .hero__title {
      width: 190px;
      height: 280px; }
      .hero__title-logo {
        width: 180px;
        height: 180px;
        top: 67%;
        transform: translate(-50%, -73%); }
      .hero__title-logo2 {
        transform: translate(-50%, 120%);
        width: 150px;
        height: 50px; }
      .hero__title-logo3 {
        transform: translate(-50%, 120%);
        width: 40px; }
      .hero__title span {
        font-size: 25px; }
  .main__title-wrapper {
    padding-top: 80px; }
  .work__h1 {
    font-size: 24px; }
    .work__h1::after {
      width: 120px; }
  .work__h2 {
    font-size: 20px; }
  .work__inner {
    padding: 30px 0 50px; }
  .work__person01 {
    width: 100px;
    top: 700px;
    right: -30px; }
  .work__person02 {
    width: 70px; }
  .work__images {
    right: -35%; }
  .about__h1 {
    font-size: 24px; }
    .about__h1::after {
      width: 270px; }
  .about__images {
    left: -18%; }
  .about__person03 {
    width: 50px;
    top: -1px; }
  .about__des {
    margin-top: 50px; }
    .about__des-title {
      width: auto;
      padding-right: 0; }
  .about__txt {
    width: 100%;
    text-align: left; }
    .about__txt-wrapper {
      width: 90%;
      text-align: center; }
  .about__panel {
    width: 80%; }
    .about__panel-wrapper {
      margin-top: 30px; }
  .staff__info-images > img {
    margin-left: 90px; }
    .staff .staff__des:nth-child(2n) .staff__info-images > img {
      margin-left: -90px; }
  .staff__h1 {
    font-size: 24px; }
    .staff__h1::after {
      width: 140px; }
  .staff__person04 {
    width: 120px;
    top: -110px;
    right: 30px; }
  .staff__mainimages {
    width: 95%; }
    .staff__mainimages::after {
      content: "";
      display: block;
      padding-top: 50%; }
  .contact-br {
    display: block; }
  .contact img {
    margin-left: 105%; }
  .footer__adress {
    font-size: 13px;
    margin-top: 10px; }
  .footer__scroll > a {
    opacity: 0.6; }
  .footer__bottom {
    font-size: 13px; } }

@media screen and (max-width: 529px) {
  .main {
    height: 460px; }
    .main__images {
      width: 360px;
      bottom: -35%; } }

@media screen and (max-width: 500px) {
  .main {
    height: 420px; }
    .main__images {
      width: 340px;
      bottom: -40%; } }
