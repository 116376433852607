/* loadinging背景画面設定　*/
.loading {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2999;
  background: #1f2c5c;
  text-align: center;
  color: #fff;
  padding-top: 90px;

  &__images {
    width: 100%;
    height: 100%;
  }

  &__cover {
    background-image: url(../images/hero-cover.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__logo {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 900px;
    transform: translate(-50%, 0%);
    z-index: 3000;
  }

  &__title {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -51%);
    font-size: 30px;
    font-weight: 700;
    color: white;
    padding-bottom: 300px;
    opacity: 0;
    letter-spacing: -5px;
    // z-index: 3000;

    & span {
      opacity: 0;
      -webkit-transition: 0.6s ease-in-out;
      transition: 0.6s ease-in-out;
    }
  }
  &__title01 {
    background-image: url(../images/hero-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 260px;
    display: none;
  }
  &__title02 {
    background-image: url(../images/hero-marumasa.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 300%);
    width: 200px;
    height: 50px;
    display: none;
  }
}
