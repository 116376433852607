body {
  font-family: YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック",
    "Yu Gothic", "Sogoe UI", sans-serif;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.font-mp {
  font-family: "M PLUS Rounded 1c", sans-serif;
}
.font-sm {
  font-size: 14px;
}
.font-md {
  font-size: 18px;
}
.font-lr {
  font-size: 25px;
}
.font-lg {
  font-size: 36px;
}
.mb-sm {
  margin-bottom: 16px !important;
}
.mb-lg {
  margin-bottom: -55px !important;
}
.pt-lg {
  padding-top: 170px;
}
.pb-sm {
  padding-bottom: 16px;
}
.pb-lg {
  padding-bottom: 80px !important;
}

a {
  text-decoration: none !important;
}
.content-width {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}

.sub-title {
  font-size: 34px;
  color: black;
  font-weight: 700;
  @extend .font-mp;
  margin-bottom: 20px;
}


#global-container {
  overflow: hidden;
}

.mobile-menu {
  &__btn {
    display: none;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navHeight;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #1f2c5c;
  font-weight: bold;
  z-index: 2500;

  &.triggered {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 50px;
  }

  &__title {
    &-syokunin {
      @extend .font-md;
    }
  }

  &__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__li {
    margin-left: 50px;

    & > a {
      text-decoration: none !important;
      text-transform: uppercase;
      @extend .font-md;
      color: #1f2c5c;
      font-weight: bold;
    }
  }

  & .logo {
    @extend .font-lg;
    justify-content: center;

    & > a {
      color: #1f2c5c;
    }
  }
}

.main {
  background-image: url(../images/main-back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;

  &__title {
    &-wrapper {
      text-align: center;
      padding-top: 100px;
    }
  }

  &__subtitle {
    margin-top: 30px;
  }

  &__images {
    background-image: url(../images/main-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 900px;
    height: 450px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: -7%;
    left: 50%;
    transform: translate(-50%, -7%);
    z-index: 1;

    &::after {
      content: "";
      display: block;
      padding-top: 40%;
    }
  }
}

// 左官の仕事
.work {
  background-image: url(../images/cover01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fdf3a0;
  padding: 100px 0;

  &__inner {
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0;
    z-index: 1;
    position: relative;
  }

  &__title {
    text-align: center;
    margin-top: 40px;
  }
  &__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 200px;
      height: 10px;
      background-color: #fdf3a0;
    }
  }

  &__des {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__txt {
    &-wrapper {
      width: 50%;
      margin-top: 50px;
      position: relative;
      left: 70px;
    }
  }

  &__images {
    width: 80%;
    position: relative;
    right: -13%;

    & > img {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  &__person01 {
    position: absolute;
    top: 500px;
    right: -100px;
  }
  &__person02 {
    position: absolute;
    bottom: -160px;
    left: -80px;
  }

  &__info {
    text-align: center;
    margin-top: 80px;
  }
  &__h2 {
    font-size: 34px;
    color: #1f2c5c;
    font-weight: 700;
  }

  &__panel {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &-images {
      width: 21%;
      height: 21%;
      margin-left: 30px;
      margin-bottom: 40px;

      &:nth-child(1),
      &:nth-child(5) {
        margin-left: 0;
      }
    }
  }

  &__btn {
    display: block;
    text-align: center;
  }
}



// マルマサ工業の取り組み
.about {
  background-image: url(../images/cover03.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fdaca0;
  padding: 100px 0;
  z-index: 3;

  &__inner {
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0;
    z-index: 1;
  }

  &__title {
    text-align: center;
    position: relative;
    z-index: 2;
  }

  &__person03 {
    position: absolute;
    z-index: 1;
    right: 230px;
    top: -10px;
  }
  &__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 450px;
      height: 10px;
      background-color: #fdaca0;
    }
  }

  &__des {
    margin-top: 80px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    &-title {
      width: 400px;
      padding-right: 30px;
    }
  }

  &__txt {
    &-wrapper {
      margin-top: 70px;
      width: 50%;
      position: relative;
      right: 70px;
    }
  }

  &__images {
    width: 80%;
    position: relative;
    left: -15%;

    & > img {
      object-fit: cover;
      width: 100%;
      border-radius: 20px;
    }
  }

  &__panel {
    width: 29%;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }

    &-title {
      font-size: 20px;
      font-weight: 700;
      color: #1f2c5c;
      text-align: center;
      margin-bottom: 10px;
      height: 50px;
    }

    &-txt {
      color: #1f2c5c;
      margin-top: 20px;
    }

    &-wrapper {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-images {
      text-align: center;
      & > img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}


// スタッフ紹介
.staff {
  background-image: url(../images/cover02.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #6fabe2;
  padding: 100px 0;
  z-index: 100;

  &__inner {
    width: 77%;
    margin: 0 auto;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 0;
  }

  &__title {
    text-align: center;
  }
  &__h1 {
    font-size: 42px;
    color: #1f2c5c;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 250px;
      height: 10px;
      background-color: #6fabe2;
    }
  }

  &__mainimages {
    background-image: url(../images/main-img01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

    &::after {
      content: "";
      display: block;
      padding-top: 40%;
    }
  }

  &__des {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    & p,
    div {
      margin-bottom: 0;
    }

    &-titlewrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__txt {
    margin-top: 30px;
    &-wrapper {
      width: 70%;
      margin-top: 50px;
      position: relative;
      left: 90px;

      .staff .staff__des:nth-child(2n) & {
        right: 70px;
        left: auto;
      }
    }
  }

  &__images {
    width: 100%;
    position: relative;
    left: 195px;

    .staff .staff__des:nth-child(2n) & {
      left: auto;
      right: 215px;
    }

    & > img {
      object-fit: cover;
      width: 100%;
      border-radius: 20px;
    }
  }

  &__person04 {
    position: absolute;
    top: -300px;
    right: 162px;
    z-index: 1;
  }

  &__info {
    text-align: center;
    margin-top: 80px;
  }
  &__h2 {
    font-size: 24px;
    color: #1f2c5c;
    font-weight: 700;
  }

  &__panel {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &-images {
      width: 21%;
      height: 21%;
      margin-left: 30px;
      margin-bottom: 40px;

      &:nth-child(1),
      &:nth-child(5) {
        margin-left: 0;
      }
    }
  }

  &__btn {
    display: block;
    text-align: center;
  }

  &__info {
    &-wrap {
      display: none;
    }
  }
}

.contact {
  width: 100%;
  height: 160px;
  background-color: #d26889;
  text-align: center;
  padding: 20px 0;
  position: relative;
  
  & > a {
    color: #fdf3a0;
    display: inline-block;
  }

  & > h1 {
    font-size: 43px;
    font-weight: 700px;
  }
  & p {
    margin: 0;
  }
  & img {
    margin-left: 600px;
    position: relative;
    bottom: 70px;
  }

  &-br {
    display: none;
  }
}

.footer {
  margin-top: 50px;
  &__inner {
    width: 80%;
    margin: 0 auto;
    // max-width: 1300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__adress {
    display: block;
    margin-top: 20px;
    & p {
      margin: 0;
    }
  }

  &__bottom {
    text-align: center;
    margin: 50px 0;
  }

  &__scroll {
    text-align: center;
    position: relative;
    & > a {
      position: fixed;
      bottom: 0;
      right: 0;
      display: block;
      width: 70px;
      height: 70px;
      background-color: #1f2c5c;
      z-index: 300;

      & > img {
        margin-top: 30px;
      }
    }
  }
}
