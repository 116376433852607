.hero {
  padding-top: 90px;
  position: relative;

  &__images {
    height: calc(100vh - 90px);
  }

  &__logo {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 900px;
    transform: translate(-50%, 0%);
    z-index: 3000;
  }

  &__title {
    background-image: url(../images/logo__title.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 10%;
    left: 49%;
    transform: translate(-50%, -98%);
    width: 250px;
    height: 260px;
    display: none;
    // z-index: 3000;

    &-logo {
      background-image: url(../images/hero-img01.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
      height: 260px;
      display: none;
      // z-index: 3000;
    }
    &-logo2 {
      background-image: url(../images/hero-marumasa.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, 300%);
      width: 200px;
      height: 50px;
      display: none;
    }
    &-logo3 {
      background-image: url(../images/hero-scroll.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 300%;
      left: 50%;
      transform: translate(-50%, 270%);
      width: 50px;
      height: 100px;
      display: none;
    }
  }

  // トップ動画
  &__movie {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 130%;
    min-height: 130%;

    &-wrapper {
      position: relative;
      width: 100vw;
      height: calc(100vh - 90px);
      overflow: hidden;
    }

    &-dotto {
      background: url(../images/ovlay.png);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

