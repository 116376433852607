@media screen and (max-width:780px) {
   
    .work {
        &__person01 {
            top: 550px;
            right: -50px;
        }
    }

}

