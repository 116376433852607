@media  screen and (max-width:1280px) {

  .header {
      &__li {
        margin-left: 40px;

      }
  }

  .staff {
    &__images {
      left: 150px;

      .staff .staff__des:nth-child(2n) & {
        right: 130px;
      }
    }
  }

}

@media  screen and (max-width:1120px) {

  .sub-title {
    font-size: 30px;
  }

  .header {
      &__li {
        margin-left: 20px;

        & > a {
            font-size: 15px;
        }
      }
  }
}


