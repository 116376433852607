.btn {
  position: relative;
  display: inline-block;
  font-weight: 600;
  margin: 10px auto;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  text-decoration: none !important;

  &.filled {
    width: 200px;
    height: 90px;
    line-height: 90px;
    background-color: #1f2c5c;
    color: $cWhite;
    margin: 0;

    &:hover {
      opacity: 0.9;
    }
  }

  &.circle {
    width: 230px;
    height: 60px;
    line-height: 60px;
    background-color: #1f2c5c;
    color: #fdf3a0;
    border-radius: 40px;
    outline: none ;

    &:hover {
      opacity: 0.9;
    }
  }
}

.btn-blue {
  background:#1f2c5c ;
  color: #fff !important;
}
