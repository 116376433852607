@media screen and (max-width: 960px) {
  .mobile-menu {
    &__btn {
      display: block;
      margin-right: 30px;
    }
  }

  .header {
    z-index: 4500;
    &__nav {
      display: none;
    }

    &__li {
      margin-left: 30px;
    }
  }

  .main {
    height: 600px;
    &__images {
      width: 650px;
      bottom: -15%;
    }
  }

  .work {
    &__des {
      flex-direction: column;
    }
    &__txt {
      &-wrapper {
        width: 90%;
        margin: 0 auto;
        left: auto;
      }
    }
    &__images {
      right: -33%;
    }
    &__person01 {
      width: 160px;
      top: 750px;
    }
    &__person02 {
      width: 110px;
      bottom: -120px;
      left: -40px;
    }

    &__info {
      margin-top: 100px;
    }

    &__panel {
      & > img {
        width: 40%;

        &:nth-child(3),
        &:nth-child(7) {
          margin-left: 0;
        }
      }
    }
  }

  .about {
    &__des {
      flex-direction: column;

      &-title {
        width: auto;
      }
    }
    &__txt {
      &-wrapper {
        width: 80%;
        right: 0;
        margin: 0 auto;
      }
    }

    &__images {
      left: -14%;
    }

    &__h1 {
      font-size: 34px;

      &::after {
        width: 360px;
      }
    }

    &__panel {
      margin-top: 40px;
      margin-left: 0;
      width: 70%;
      &-wrapper {
        flex-direction: column;
        align-items: center;
      }

      &-txt {
        margin-top: 20px;
      }
    }

    &__person03 {
      width: 80px;
      right: 100px;
    }
  }

  .staff {
    &__backcolor {
      height: 5000px !important;
    }

    &__des {
      flex-direction: column;
      margin-top: 100px;

      &:nth-child(2n) {
        flex-direction: column;
      }
    }

    &__txt {
      &-wrapper {
        width: 90%;
        margin: 0 auto;
        position: relative;
        left: 0;

        .staff .staff__des:nth-child(2n) & {
          width: 90%;
          right: 0;
          left: 0;
        }
      }
    }

    &__images {
      width: 90%;
      position: relative;
      left: 170px;
      margin-top: 50px;

      .staff .staff__des:nth-child(2n) & {
        right: 105px;
      }
    }
    &__person04 {
      width: 160px;
      top: -180px;
      right: 102px;
    }

    &__mainimages {
      width: 95%;

    }

    &__txt {
      display: none;
    }
    &__images {
      display: none;
    }

    &__info {
      &-wrap {
        display: flex;
        flex-direction: column-reverse;
      }

      &-txt {
        width: 90%;
        margin: 0 auto;
      }

      &-images {
        object-fit: cover;

        & > img {
          // width: 80%;
          // height: 80%;
          margin-left: 110px;
          margin-top: 50px;
          margin-bottom: 20px;
          border-radius: 20px;

          .staff .staff__des:nth-child(2n) & {
            margin-left: -110px;
          }
        }
      }
    }
  }

  .footer {
    &__inner {
      flex-direction: column;
    }
    &__right {
      margin-top: 30px;
    }
  }
}
