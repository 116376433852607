// パララックス画像
.parallax {
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;

  &.bg-1 {
    background-image: url(../images/palalax.png);
    // z-index: 10;
  }
}
.is-ios .parallax {
  background-attachment: scroll;
}


// パララックス動画
.bv-video-wrap {
  height: 600px !important;
  z-index: -1 !important;
}
.bv-video-wrap::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(#111 30%, transparent 31%),
    radial-gradient(#111 30%, transparent 31%);
  background-size: 4px 4px;
  background-position: 0 0, 2px 2px;
  z-index: 1;
}

.bv-video-dotto {
  background: url(../images/ovlay.png);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
